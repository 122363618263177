import { StorageSerializers } from '@vueuse/core'

import type { User } from 'firebase/auth'
import type { AuthSignInProvider, AuthCallback, AuthUserResult, AuthClaims } from 'shared-types/authentication'
import type { UseStorageOptions } from '@vueuse/core'

const storeName = 'authentication'
const localStorageOpts: UseStorageOptions<any> = {
  serializer: StorageSerializers.object
}

export const useAuthenticationStore = defineStore(storeName, () => {
  const route = useRoute()
  const nuxtApp = useNuxtApp()
  const auth = nuxtApp.$firebase.auth
  const functions = nuxtApp.$firebase.functions

  const localAuthUser = useLocalStorage<AuthUserResult|null>('authUser', null, localStorageOpts)
  const localAuthEmail = useLocalStorage<string|null>('authEmail', null, localStorageOpts)

  const isLoading = ref<boolean>(false)
  const isAuthenticated = ref<boolean>(!!localAuthUser.value)
  const authUser = ref<AuthUserResult|null>(localAuthUser.value)

  async function getUserInfo (user: User): Promise<AuthUserResult> {
    const userResult = user.toJSON() as User
    const tokenResult = await user.getIdTokenResult(true)
    const claims = tokenResult.claims as AuthClaims

    return {
      ...userResult,
      claims
    }
  }

  async function sendLink (email: string): Promise<boolean> {
    isLoading.value = true

    try {
      // const url = `${appUrl}/sign-in?isAuthLink=true`
      const isEmailSent = await sendLinkToEmail(functions, email)

      if (isEmailSent) {
        localAuthEmail.value = email
      } else {
        localAuthEmail.value = null
      }

      return isEmailSent
    } catch (err) {
      const error = err as Error
      console.error('[sendLink] Error', error)

      return false
    } finally {
      isLoading.value = false
    }
  }

  async function signIn (provider: AuthSignInProvider): Promise<User|null> {
    isLoading.value = true

    try {
      let user: User|null = null

      if (provider === 'email' && localAuthEmail.value) {
        user = await signInWithEmail(auth, localAuthEmail.value)
      }

      if (provider === 'google') {
        user = await signInWithGoogle(auth)
      }

      if (provider === 'apple') {
        user = await signInWithApple(auth)
      }

      const userInfo = user ? await getUserInfo(user) : null

      authUser.value = userInfo
      localAuthUser.value = userInfo
      localAuthEmail.value = null
      isAuthenticated.value = !!userInfo

      return user
    } catch (err) {
      const error = err as Error
      console.error('[signIn] Error', error)

      return null
    } finally {
      isLoading.value = false
    }
  }

  async function signOut (): Promise<boolean> {
    isLoading.value = true

    try {
      await logout(auth)
      return true
    } catch (err) {
      const error = err as Error
      console.error('[signOut] Error', error)

      return false
    } finally {
      resetAuth()
      isLoading.value = false
    }
  }

  function listenAuth (callback?: AuthCallback): void {
    listenAuthStateChanged(auth, async user => {
      if (!user) {
        resetAuth()

        if (callback) {
          await callback(null)
        }
        return
      }

      const userInfo = await getUserInfo(user)

      authUser.value = userInfo
      localAuthUser.value = userInfo
      isAuthenticated.value = true

      if (callback) {
        await callback(user)
      }
    })
  }

  function resetAuth (): void {
    authUser.value = null
    localAuthUser.value = null

    if (!route.path.includes('/sign-in')) {
      localAuthEmail.value = null
    }

    isLoading.value = false
    isAuthenticated.value = false
  }

  return {
    isLoading,
    isAuthenticated,
    authUser,
    authEmail: localAuthEmail,
    sendLink,
    signIn,
    signOut,
    listenAuth,
    resetAuth
  }
})
