import { StorageSerializers } from '@vueuse/core'

import type { DocumentData } from 'firebase/firestore'
import type { EnterpriseData } from 'shared-types/enterprise'
import type { Data } from 'shared-types/data'
import type { UseStorageOptions } from '@vueuse/core'

const storeName = 'enterprise'
const collectionName = storeName + 's'

const localStorageOpts: UseStorageOptions<any> = {
  serializer: StorageSerializers.object
}

export const useEnterpriseStore = defineStore(storeName, () => {
  const authenticationStore = useAuthenticationStore()
  const { isAuthenticated } = storeToRefs(authenticationStore)

  const nuxtApp = useNuxtApp()
  const firestore = nuxtApp.$firebase.firestore
  const database = shallowRef(firestore)

  const localEnterprises = useLocalStorage<EnterpriseData[]|null>(storeName, null, localStorageOpts)

  const isLoading = ref(false)
  const currentEnterprise = reactive<Data<EnterpriseData>>({
    initial: getInitialEnterpriseData(),
    newest: getInitialEnterpriseData()
  })
  const enterprises = ref<EnterpriseData[]>([])
  const totalEnterprises = ref<number|null>(null)
  const lastEnterpriseDoc = ref<DocumentData|null>(null)

  const hasEnterpriseChanges = computed(() => {
    const rawCurrEnterprise = toDeepRaw(currentEnterprise)
    const strInitialEnterprise = JSON.stringify(rawCurrEnterprise.initial)
    const strNewestEnterprise = JSON.stringify(rawCurrEnterprise.newest)

    return strInitialEnterprise !== strNewestEnterprise
  })

  const {
    initStore, getFirstDoc, getDoc,
    updateDoc, resetStore
  } = getStoreActions<EnterpriseData>({
    collectionName,
    state: {
      isLoading,
      isAuthenticated,
      database,
      currentDocument: currentEnterprise,
      documents: enterprises,
      localDocuments: localEnterprises,
      totalDocuments: totalEnterprises,
      lastDocument: lastEnterpriseDoc
    },
    getInitialData: getInitialEnterpriseData
  })

  return {
    isLoading,
    currentEnterprise,
    hasEnterpriseChanges,
    initEnterprise: initStore,
    getFirstEnterprise: getFirstDoc,
    getEnterprise: getDoc,
    updateEnterprise: updateDoc,
    resetEnterprise: resetStore
  }
})
