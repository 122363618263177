import { default as index1wLgGfBWr3Meta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/account/index.vue?macro=true";
import { default as account2SRakYBJ4dMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/account.vue?macro=true";
import { default as indexsKZMiDdbA4Meta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/assets/index.vue?macro=true";
import { default as assetsBjOWQF6Y9JMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/assets.vue?macro=true";
import { default as indexyaYtuVlypOMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/index.vue?macro=true";
import { default as indexeryL8X4SR8Meta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/invoices/index.vue?macro=true";
import { default as invoicesCdOgmgAzEyMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/invoices.vue?macro=true";
import { default as _91pageId_93py6BfsoUzxMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/playlists/[playlistId]/[pageId].vue?macro=true";
import { default as indexwDVI9BhWEjMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/playlists/[playlistId]/index.vue?macro=true";
import { default as _91playlistId_939oYbuk7o7ZMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/playlists/[playlistId].vue?macro=true";
import { default as indexDmf4PwEaMLMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/playlists/index.vue?macro=true";
import { default as playlistsbtIlPoVctJMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/playlists.vue?macro=true";
import { default as _91screenId_93TFiJyA9usdMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/screens/[screenId].vue?macro=true";
import { default as indexIa0LUk8aUrMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/screens/index.vue?macro=true";
import { default as screenscJkryfZBE0Meta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/screens.vue?macro=true";
import { default as sign_45in8rMofqvb06Meta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/sign-in.vue?macro=true";
import { default as sign_45upX2LWA9KXLfMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/sign-up.vue?macro=true";
import { default as indexzwe5UFayDkMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/support/index.vue?macro=true";
import { default as supporttMnHpnZA1hMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/support.vue?macro=true";
import { default as indexN74YxZMrSmMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/teams/index.vue?macro=true";
import { default as teamsUwVa8w1zVZMeta } from "/Volumes/Drive/bgd/Documents/live-display/app/pages/teams.vue?macro=true";
export default [
  {
    name: account2SRakYBJ4dMeta?.name,
    path: "/account",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/account/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: assetsBjOWQF6Y9JMeta?.name,
    path: "/assets",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/assets.vue").then(m => m.default || m),
    children: [
  {
    name: "assets",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/assets/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    redirect: "/playlists",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invoicesCdOgmgAzEyMeta?.name,
    path: "/invoices",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/invoices.vue").then(m => m.default || m),
    children: [
  {
    name: "invoices",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/invoices/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: playlistsbtIlPoVctJMeta?.name,
    path: "/playlists",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/playlists.vue").then(m => m.default || m),
    children: [
  {
    name: _91playlistId_939oYbuk7o7ZMeta?.name,
    path: ":playlistId()",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/playlists/[playlistId].vue").then(m => m.default || m),
    children: [
  {
    name: "playlists-playlistId-pageId",
    path: ":pageId()",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/playlists/[playlistId]/[pageId].vue").then(m => m.default || m)
  },
  {
    name: "playlists-playlistId",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/playlists/[playlistId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "playlists",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/playlists/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: screenscJkryfZBE0Meta?.name,
    path: "/screens",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/screens.vue").then(m => m.default || m),
    children: [
  {
    name: "screens-screenId",
    path: ":screenId()",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/screens/[screenId].vue").then(m => m.default || m)
  },
  {
    name: "screens",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/screens/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45in8rMofqvb06Meta || {},
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upX2LWA9KXLfMeta || {},
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: supporttMnHpnZA1hMeta?.name,
    path: "/support",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/support.vue").then(m => m.default || m),
    children: [
  {
    name: "support",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/support/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: teamsUwVa8w1zVZMeta?.name,
    path: "/teams",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/teams.vue").then(m => m.default || m),
    children: [
  {
    name: "teams",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/app/pages/teams/index.vue").then(m => m.default || m)
  }
]
  }
]