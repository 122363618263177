import type { AddToArrayOptions } from '../types/array'

export function addToArray <T extends Record<string, any>>(
  target: T[],
  source: T|T[],
  options: AddToArrayOptions
): void {
  const { mainKey, sortKey, orderBy = 'desc' } = options
  const isDesc = orderBy === 'desc'

  const sources = Array.isArray(source) ? source : [source]

  for (const src of sources) {
    const targetIndex = target.findIndex(targetValue => {
      return targetValue[mainKey] === src[mainKey]
    })

    if (targetIndex !== -1) {
      target.splice(targetIndex, 1, src)
      continue
    }

    if (isDesc) {
      target.unshift(src)
      continue
    }

    target.push(src)
  }

  if (!sortKey) {
    return
  }

  target.sort((targetValue1, targetValue2) => {
    const value1 = targetValue1[sortKey]
    const value2 = targetValue2[sortKey]

    if (value1 < value2) {
      return isDesc ? 1 : -1
    }

    if (value1 > value2) {
      return isDesc ? -1 : 1
    }

    return 0
  })
}
