export default defineNuxtRouteMiddleware(to => {
  const authenticationStore = useAuthenticationStore()
  const { isAuthenticated } = storeToRefs(authenticationStore)

  const userStore = useUserStore()
  const { currentUser } = storeToRefs(userStore)

  // Public page
  if (isSamePath(to, '/support')) {
    return
  }

  // User not authenticated
  if (
    !isAuthenticated.value &&
    !isSamePath(to, '/sign-in')
  ) {
    return navigateTo('/sign-in')
  }

  // User not registered
  if (
    isAuthenticated.value &&
    !currentUser.value.initial.id &&
    !isSamePath(to, '/sign-up')
  ) {
    return navigateTo('/sign-up')
  }

  const isSamePaths = (
    isSamePath(to, '/sign-in') ||
    isSamePath(to, '/sign-up')
  )

  // User authenticated and registered
  if (
    isAuthenticated.value &&
    currentUser.value.initial.id &&
    isSamePaths
  ) {
    return navigateTo('/playlists')
  }
})
