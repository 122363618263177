import { DateTime } from 'luxon'

import type { DateObjectUnits, DurationObjectUnits, DurationUnits } from 'luxon'
import type {
  DatetimeType, DatetimeIsoDateFormat, DatetimeIsoTimeFormat, DatetimeWeekDayFormat,
  DatetimeDateFormat, DatetimeWeekDay, InitialDatetime, DatetimeObject
} from '../types/datetime'

export const weekDayFormat = 'cccc'
export const dateFormat = 'yyyy-MM-dd'
export const timeFormat = 'HH:mm:ss'

export function getUtcDatetime (isoDatetime?: string): DateTime {
  return isoDatetime
    ? DateTime.fromISO(isoDatetime).set({ millisecond: 0 }).toUTC()
    : getCurrDatetime()
}

export function getUtcTime (isoDatetime?: string): DatetimeIsoTimeFormat {
  const utcDatetime = getUtcDatetime(isoDatetime)
  return utcDatetime.toFormat(timeFormat) as DatetimeIsoTimeFormat
}

export function getTimestamp (isoDatetime?: string): number {
  const utcDatetime = getUtcDatetime(isoDatetime)
  return utcDatetime.toMillis()
}

export function getTimeZone (): string {
  const localeDatetime = DateTime.local()
  return localeDatetime.zoneName
}

/*
  Current datetime
*/

export function getCurrDatetime (isUTC = true): DateTime {
  const datetime = DateTime.local().set({ millisecond: 0 })
  return isUTC ? datetime.toUTC() : datetime
}

export function getCurrIsoDatetime (isUTC = true): string {
  const utcDatetime = getCurrDatetime(isUTC)
  return utcDatetime.toISO()!
}

export function getCurrIsoWeekDay (isUTC = true): DatetimeWeekDay {
  const utcDatetime = getCurrDatetime(isUTC)
  return utcDatetime.toFormat(weekDayFormat) as DatetimeWeekDay
}

export function getCurrIsoDate (isUTC = true): DatetimeIsoDateFormat {
  const utcDatetime = getCurrDatetime(isUTC)
  return utcDatetime.toFormat(dateFormat) as DatetimeIsoDateFormat
}

export function getCurrIsoTime (isUTC = true): DatetimeIsoTimeFormat {
  const utcDatetime = getCurrDatetime(isUTC)
  return utcDatetime.toFormat(timeFormat) as DatetimeIsoTimeFormat
}

/*
  Next datetime
*/

export function getNextDatetime (isoDatetime?: string): DateTime {
  const utcDatetime = getUtcDatetime(isoDatetime)

  return utcDatetime.set({
    month: utcDatetime.month + 1
  })
}

export function getNextIsoDatetime (isoDatetime?: string): string {
  const utcDatetime = getNextDatetime(isoDatetime)
  return utcDatetime.toISO()!
}

/*
  Previous datetime
*/

export function getPrevDatetime (isoDatetime?: string): DateTime {
  const utcDatetime = getUtcDatetime(isoDatetime)

  return utcDatetime.set({
    month: utcDatetime.month - 1
  })
}

export function getPrevIsoDatetime (isoDatetime?: string): string {
  const utcDatetime = getPrevDatetime(isoDatetime)
  return utcDatetime.toISO()!
}

/*
  Partial datetime
*/

export function getDatetimeFromTime (partialDatetime: DatetimeIsoTimeFormat, isUTC = true): DateTime {
  const localeDatetime = DateTime.fromFormat(partialDatetime, timeFormat, {
    zone: isUTC ? 'local' : 'utc'
  })
  const utcDatetime = isUTC ? localeDatetime.toUTC() : localeDatetime.toLocal()

  return utcDatetime.set({ millisecond: 0 })
}

export function getDatetimeFromWeekDay (partialDatetime: DatetimeWeekDayFormat, isUTC = true): DateTime {
  const localeDatetime = DateTime.fromFormat(partialDatetime, `${weekDayFormat} ${timeFormat}`, {
    zone: isUTC ? 'local' : 'utc'
  })
  const utcDatetime = isUTC ? localeDatetime.toUTC() : localeDatetime.toLocal()

  const weekDays = getWeekDays()
  const todayWeekDay = getCurrIsoWeekDay()
  const todayIndex = weekDays.findIndex(weekDay => weekDay === todayWeekDay)
  const partialIndex = weekDays.findIndex(weekDay => partialDatetime.includes(weekDay))
  const weekNumber = partialIndex < todayIndex ? utcDatetime.weekNumber + 1 : utcDatetime.weekNumber

  return utcDatetime.set({ weekNumber, millisecond: 0 })
}

export function getDatetimeFromDate (partialDatetime: DatetimeDateFormat, isUTC = true): DateTime {
  const localeDatetime = DateTime.fromFormat(partialDatetime, `${dateFormat} ${timeFormat}`, {
    zone: isUTC ? 'local' : 'utc'
  })
  const utcDatetime = isUTC ? localeDatetime.toUTC() : localeDatetime.toLocal()

  return utcDatetime.set({ millisecond: 0 })
}

/*
  Formatting datetime
*/

export function getFirstIsoDatetime (isoDatetime?: string): string {
  const utcDatetime = getUtcDatetime(isoDatetime)
  return utcDatetime.startOf('month').toISO()!
}

export function getLastIsoDatetime (isoDatetime?: string): string {
  const utcDatetime = getUtcDatetime(isoDatetime)
  return utcDatetime.endOf('month').toISO()!
}

export function getDatetimeFullObject (isoDatetime?: string): DateObjectUnits {
  const utcDatetime = getUtcDatetime(isoDatetime)
  return utcDatetime.toObject()
}

export function getDatetimeObject (isoDatetime?: string): DatetimeObject {
  const { year = 0, month = 0, day = 0 } = getDatetimeFullObject(isoDatetime)

  return {
    year: String(year),
    month: String(month).padStart(2, '0'),
    day: String(day).padStart(2, '0')
  }
}

export function formatDatetime (isoDatetime: string, type: DatetimeType = 'datetime') {
  const localeDatetime = DateTime.fromISO(isoDatetime)

  if (type === 'date') {
    return localeDatetime.toUTC().toFormat('D')
  }

  if (type === 'time') {
    return localeDatetime.toFormat('t')
  }

  return localeDatetime.toFormat('f')
}

export function getInitialDatetime (): InitialDatetime {
  const utcIsoDatetime = getCurrIsoDatetime()

  return {
    creationDate: utcIsoDatetime,
    updationDate: utcIsoDatetime,
    deletionDate: null
  }
}

export function getInvoicePeriod (startDate: string, endDate: string) {
  const { day: startDay } = getDatetimeObject(startDate)
  const { day: endDay, month, year } = getDatetimeObject(endDate)

  return `${startDay}-${endDay}/${month}/${year}`
}

export function getIsoDatetimeDiff (
  isoDatetime1: string,
  isoDatetime2: string,
  durationUnits: DurationUnits = ['days']
): DurationObjectUnits {
  const datetime1 = getUtcDatetime(isoDatetime1)
  const datetime2 = getUtcDatetime(isoDatetime2)
  const diff = datetime1.diff(datetime2, durationUnits)

  return diff.toObject()
}

/*
  Week days
*/

export function getWeekDays (): DatetimeWeekDay[] {
  return [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]
}

export function getTodayWeekDays (): DatetimeWeekDay[] {
  const weekDays = getWeekDays()
  const currWeekDay = getCurrIsoWeekDay()
  const currWeekDayIndex = weekDays.findIndex(weekDay => weekDay === currWeekDay)

  if ([-1, 0].includes(currWeekDayIndex)) {
    return weekDays
  }

  const firstWeekDays = weekDays.slice(currWeekDayIndex)
  const nextWeekDays = weekDays.slice(0, currWeekDayIndex)

  return [
    ...firstWeekDays,
    ...nextWeekDays
  ]
}

export function getWeekDayTranslation (language: 'fr', weekDay: DatetimeWeekDay) {
  const translation = {
    'fr': {
      Monday: 'Lundi',
      Tuesday: 'Mardi',
      Wednesday: 'Mercredi',
      Thursday: 'Jeudi',
      Friday: 'Vendredi',
      Saturday: 'Samedi',
      Sunday: 'Dimanche'
    }
  }

  return translation?.[language]?.[weekDay] || ''
}
