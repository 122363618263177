import _mergeWith from 'lodash.mergewith'

export function isObject (value: unknown): boolean {
  return (
    value !== null &&
    !Array.isArray(value) &&
    typeof value !== 'undefined' &&
    typeof value === 'object'
  )
}

export function getRawData <T>(data: T): T {
  return isReactive(data) ? toRaw(data) : data
}

export function toDeepRaw <T>(data: T): T {
  const rawData = getRawData<T>(data)

  for (const key in rawData) {
    const value = rawData[key]

    if (!isObject(value) && !Array.isArray(value)) {
      continue
    }

    rawData[key] = toDeepRaw<typeof value>(value)
  }

  return rawData
}

export function cloneObject <T>(object: T): T {
  if (
    object === null ||
    typeof object === 'undefined' ||
    typeof object !== 'object'
  ) {
    return object
  }

  if ('structuredClone' in window) {
    return window.structuredClone<T>(object)
  }

  return JSON.parse(
    JSON.stringify(object)
  ) as T
}

// https://stackoverflow.com/a/40027890/10531083
export function mergeObject<T> (object1: T, object2: T|Partial<T>, hasArrayMerged = true): T {
  return _mergeWith(object1, object2, (objValue, srcValue) => {
    if (!Array.isArray(objValue)) {
      return
    }

    if (hasArrayMerged) {
      return objValue.concat(srcValue)
    }

    return srcValue
  })
}
