import {
  isSignInWithEmailLink, signInWithEmailLink, signInWithPopup,
  GoogleAuthProvider, OAuthProvider, signOut, onAuthStateChanged
} from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'

import type { Functions } from 'firebase/functions'
import type { Auth, User } from 'firebase/auth'
import type { AuthSignInPayload, AuthCallback } from 'shared-types/authentication'

/**
 * Send link to current user email
 * @see https://firebase.google.com/docs/auth/web/email-link-auth
 * @see https://stackoverflow.com/a/71770767/10531083
 * @param {Functions} functions
 * @param {string} email
 * @returns {Promise<boolean>}
 */
export async function sendLinkToEmail (
  functions: Functions,
  email: string
): Promise<boolean> {
  try {
    const payload: AuthSignInPayload = { email }
    const callableRequest = httpsCallable<AuthSignInPayload, boolean>(functions, 'sendLinkToEmail')
    const callableResponse = await callableRequest(payload)
    const isEmailSent = callableResponse.data

    if (!isEmailSent) {
      throw new Error('Email not sent')
    }

    console.log('[sendLinkToEmail] Email sent', payload)

    return true
  } catch (error) {
    console.error('[sendCustomEmailWithLink] Error', error)
    return false
  }
}

/**
 * Sign in current user with email
 * @see https://firebase.google.com/docs/auth/web/email-link-auth
 * @param {Auth} authentication
 * @param {string} email
 * @returns {Promise<User|null>}
 */
export async function signInWithEmail (authentication: Auth, email: string): Promise<User|null> {
  try {
    const currentUrl = window.location.href
    const isEmailLink = isSignInWithEmailLink(authentication, currentUrl)

    if (!isEmailLink) {
      throw new Error('No email link')
    }

    const result = await signInWithEmailLink(authentication, email, currentUrl)
    const user = result.user

    console.log('[signInWithEmail] Result', user)

    return user
  } catch (error) {
    console.error('[signInWithEmail] Error', error)
    return null
  }
}

/**
 * Sign in current user with Google
 * @see https://firebase.google.com/docs/reference/js/auth.googleauthprovider
 * @see https://firebase.google.com/docs/reference/js/auth.md#signinwithpopup
 * @param {Auth} authentication
 * @returns {Promise<User|null>}
 */
export async function signInWithGoogle (authentication: Auth): Promise<User|null> {
  try {
    const provider = new GoogleAuthProvider()
    const result = await signInWithPopup(authentication, provider)
    const user = result.user

    console.log('[signInWithGoogle] Result', user)

    return user
  } catch (error) {
    console.error('[signInWithGoogle] Error', error)
    return null
  }
}

/**
 * Sign in current user with Apple
 * @see https://firebase.google.com/docs/reference/js/auth.oauthprovider
 * @see https://firebase.google.com/docs/reference/js/auth.md#signinwithpopup
 * @param {Auth} authentication
 * @returns {Promise<User|null>}
 */
export async function signInWithApple (authentication: Auth): Promise<User|null> {
  try {
    const provider = new OAuthProvider('apple.com')
    const result = await signInWithPopup(authentication, provider)
    const user = result.user

    console.log('[signInWithApple] Result', user)

    return user
  } catch (error) {
    console.error('[signInWithApple] Error', error)
    return null
  }
}

/**
 * Listen auth state changed
 * @see https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
 * @param {Auth} authentication
 * @param {AuthCallback} callback
 * @returns {void}
 */
export function listenAuthStateChanged (authentication: Auth, callback: AuthCallback): void {
  onAuthStateChanged(authentication, async user => {
    try {
      console.log('[listenAuthStateChanged] Result', user)

      if (user) {
        await callback(user)
        return
      }

      await callback(null)
    } catch (error) {
      console.error('[listenAuthStateChanged] Error', error)
    }
  })
}

/**
 * Sign out current user
 * @see https://firebase.google.com/docs/reference/js/auth.md#signout
 * @param {Auth} authentication
 * @returns {Promise<boolean>}
 */
export async function logout (authentication: Auth): Promise<boolean> {
  try {
    await signOut(authentication)
    return true
  } catch (error) {
    console.error('[logout] Error', error)
    return false
  }
}
