import { mergeObject } from '../utils/object'
import { required } from '@vuelidate/validators'

import type { TeamData, TeamRules } from 'shared-types/team'

export function getInitialTeamData (teamData: Partial<TeamData> = {}): TeamData {
  const defaultData: TeamData = {
    id: '',
    enterpriseId: '',
    userIds: [],
    keywords: '',
    name: '',
    roles: [],
    isAdmin: false,
    options: {},
    isDeleted: false,
    creationDate: '',
    updationDate: '',
    deletionDate: null
  }

  return mergeObject(defaultData, teamData)
}

export function getInitialTeamRules (): TeamRules {
  return {
    id: {},
    enterpriseId: { required },
    userIds: { required },
    keywords: {},
    name: { required },
    roles: { required },
    isAdmin: { required },
    options: {},
    isDeleted: { required },
    creationDate: {},
    updationDate: {},
    deletionDate: {}
  }
}
