import { StorageSerializers } from '@vueuse/core'

import type { DocumentData } from 'firebase/firestore'
import type { TeamData } from 'shared-types/team'
import type { Data } from 'shared-types/data'
import type { UseStorageOptions } from '@vueuse/core'

const storeName = 'team'
const collectionName = storeName + 's'

const localStorageOpts: UseStorageOptions<any> = {
  serializer: StorageSerializers.object
}

export const useTeamStore = defineStore(storeName, () => {
  const authenticationStore = useAuthenticationStore()
  const { isAuthenticated } = storeToRefs(authenticationStore)

  const userStore = useUserStore()
  const { currentUser } = userStore

  const nuxtApp = useNuxtApp()
  const firestore = nuxtApp.$firebase.firestore
  const database = shallowRef(firestore)

  const localTeams = useLocalStorage<TeamData[]|null>(storeName, null, localStorageOpts)

  const isLoading = ref(false)
  const currentTeam = reactive<Data<TeamData>>({
    initial: getInitialTeamData({
      enterpriseId: currentUser.initial.enterpriseId
    }),
    newest: getInitialTeamData({
      enterpriseId: currentUser.initial.enterpriseId
    })
  })
  const teams = ref<TeamData[]>([])
  const totalTeams = ref<number|null>(null)
  const lastTeamDoc = ref<DocumentData|null>(null)

  const hasTeamChanges = computed(() => {
    const rawCurrTeam = toDeepRaw(currentTeam)
    const strInitialTeam = JSON.stringify(rawCurrTeam.initial)
    const strNewestTeam = JSON.stringify(rawCurrTeam.newest)

    return strInitialTeam !== strNewestTeam
  })

  const {
    initStore, countDocs, findDocs, addDoc,
    getFirstDoc, getDoc, getDocs, updateDoc,
    deleteDoc, resetCurrentDoc, resetStore
  } = getStoreActions<TeamData>({
    collectionName,
    state: {
      isLoading,
      isAuthenticated,
      database,
      currentDocument: currentTeam,
      documents: teams,
      localDocuments: localTeams,
      totalDocuments: totalTeams,
      lastDocument: lastTeamDoc
    },
    getInitialData: getInitialTeamData,
    documentFilter: {
      where: [
        {
          fieldName: 'enterpriseId',
          condition: '==',
          value: currentUser.initial.enterpriseId
        }
      ]
    }
  })

  return {
    isLoading,
    currentTeam,
    teams,
    totalTeams,
    lastTeamDoc,
    hasTeamChanges,
    initTeam: initStore,
    countTeams: countDocs,
    findTeams: findDocs,
    addTeam: addDoc,
    getFirstTeam: getFirstDoc,
    getTeam: getDoc,
    getTeams: getDocs,
    updateTeam: updateDoc,
    deleteTeam: deleteDoc,
    resetTeam: resetCurrentDoc,
    resetTeams: resetStore
  }
})
