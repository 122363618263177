import lang from "quasar/lang/fr.js"
import iconSet from "quasar/icon-set/material-icons.js"
import { Notify,Dialog } from "quasar"
import { QBtn,QBtnDropdown,QSelect,QInput,QStepper,QTab,QRouteTab } from "quasar"

export const componentsWithDefaults = { QBtn,QBtnDropdown,QSelect,QInput,QStepper,QTab,QRouteTab }

export const appConfigKey = "nuxtQuasar"

export const quasarNuxtConfig = {
  lang,
  iconSet,
  components: {"defaults":{"QBtn":{"noCaps":true,"color":"primary"},"QBtnDropdown":{"noCaps":true,"color":"primary"},"QSelect":{"emitValue":true,"mapOptions":true,"hideBottomSpace":true},"QInput":{"hideBottomSpace":true,"color":"grey-8"},"QStepper":{"activeColor":"primary","doneColor":"primary"},"QTab":{"noCaps":true},"QRouteTab":{"noCaps":true}}},
  plugins: {Notify,Dialog},
  config: {"notify":{"position":"bottom-right"}}
}