import type { RouteLocationNormalizedLoaded, RouteLocationNormalized } from '#vue-router'

const delimiter = '/'

export function isParentRoute (route: RouteLocationNormalizedLoaded) {
  const pathSegments = route.path.slice(1).split('/')
  return pathSegments.length === 1
}

export function isSamePath (to: RouteLocationNormalized, path: string) {
  const fullPath = !path.startsWith(delimiter)
    ? delimiter + path
    : path

  return to.path === fullPath || to.path === fullPath + delimiter
}
