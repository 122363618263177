import { StorageSerializers } from '@vueuse/core'

import type { DocumentData } from 'firebase/firestore'
import type { UserData } from 'shared-types/user'
import type { Data } from 'shared-types/data'
import type { UseStorageOptions } from '@vueuse/core'

const storeName = 'user'
const collectionName = storeName + 's'

const localStorageOpts: UseStorageOptions<any> = {
  serializer: StorageSerializers.object
}

export const useUserStore = defineStore(storeName, () => {
  const authenticationStore = useAuthenticationStore()
  const { isAuthenticated } = storeToRefs(authenticationStore)

  const nuxtApp = useNuxtApp()
  const firestore = nuxtApp.$firebase.firestore
  const database = shallowRef(firestore)

  const localUsers = useLocalStorage<UserData[]|null>(storeName, null, localStorageOpts)

  const isLoading = ref(false)
  const currentUser = reactive<Data<UserData>>({
    initial: getInitialUserData(),
    newest: getInitialUserData()
  })
  const users = ref<UserData[]>([])
  const totalUsers = ref<number|null>(null)
  const lastUserDoc = ref<DocumentData|null>(null)

  const hasUserChanges = computed(() => {
    const rawCurrUser = toDeepRaw(currentUser)
    const strInitialUser = JSON.stringify(rawCurrUser.initial)
    const strNewestUser = JSON.stringify(rawCurrUser.newest)

    return strInitialUser !== strNewestUser
  })

  const {
    initStore, countDocs, findDocs, addDoc,
    getFirstDoc, getDoc, getDocs, updateDoc,
    deleteDoc, resetCurrentDoc, resetStore
  } = getStoreActions<UserData>({
    collectionName,
    state: {
      isLoading,
      isAuthenticated,
      database,
      currentDocument: currentUser,
      documents: users,
      localDocuments: localUsers,
      totalDocuments: totalUsers,
      lastDocument: lastUserDoc
    },
    getInitialData: getInitialUserData,
    documentFilter: {
      where: [
        {
          fieldName: 'enterpriseId',
          condition: '==',
          value: currentUser.initial.enterpriseId
        }
      ]
    }
  })

  return {
    isLoading,
    currentUser,
    users,
    totalUsers,
    lastUserDoc,
    hasUserChanges,
    initUser: initStore,
    countUsers: countDocs,
    findUsers: findDocs,
    addUser: addDoc,
    getFirstUser: getFirstDoc,
    getUser: getDoc,
    getUsers: getDocs,
    updateUser: updateDoc,
    deleteUser: deleteDoc,
    resetUser: resetCurrentDoc,
    resetUsers: resetStore
  }
})
